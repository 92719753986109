import React from 'react';
import { observer } from 'mobx-react';

import { getAccessibleReactProps } from '~/util/formz/util/util';
import { MaxLengthRemainingCountdown } from '~/util/formz/Components/plugins/MaxLengthRemainingCountdown';
import { useSafeSpread } from '~/hooks/useSafeSpread';

export const Textarea = observer((props) => {
	const reactProps = getAccessibleReactProps(props);
	const {
		field: {
			control: { inputId }
		}
	} = props;
	const ariaDescribedBy = `${inputId}-descriptor`;
	const [textareaProps, textareaKey] = useSafeSpread(reactProps);
	const [countdownProps, countdownKey] = useSafeSpread(props);
	const children = props?.children || props.field?.reactProps?.children;
	const hasChildren = Boolean(children && React.Children.count(children));
	const countdown = (
		<MaxLengthRemainingCountdown
			key={countdownKey}
			{...countdownProps}
			data-qa={`${props.field.fieldId.at(-1)}-characters-remaining`}
			ariaDescribedById={ariaDescribedBy}
		/>
	);

	return (
		<div>
			<textarea {...textareaProps} key={textareaKey} aria-describedby={ariaDescribedBy} />
			{
				hasChildren
					?
					<div className="tw-flex tw-justify-between tw-gap-1 tw-flex-start">
						{countdown}
						{children}
					</div>
					:
					countdown
			}
		</div>
	);
});
