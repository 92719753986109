import React from 'react';

import { addToUrl } from '~/util/addToUrl';

import styles from '#/layout/email-signup.module.scss';

export const EmailSignUpSuccess = (props = {}) => {
	const {
		emailSignUpFormStore: {
			isEAndMWebModal,
		},
		parameters: {
			email = '',
		} = {}
	} = props;

	return (
		<div className={styles['email-signup-success']} role="alert">
			{isEAndMWebModal &&
				<p data-qa="sign-up-email-welcome">Welcome! You're signed up for Room & Board emails.</p>
			}
			{!isEAndMWebModal &&
				<div>
					<h3 className="Heading Heading--5">Thank you for subscribing</h3>
					<a href={addToUrl('/opt-in', `usr=${btoa(email)}&isNewSubscription=true`)}>
						Manage email preferences
					</a>
				</div>
			}
		</div>
	);
};
