import { computed, observable, makeObservable } from 'mobx';

class HeaderMobile {
	headerHeight = 0;

	globalDynamic: any = {};

	isAccountOpen = false;

	isMenuOpen = false;

	isScrollingUp = false;

	isSearchOpen = false;

	scrollY = 0;

	constructor({
		headerHeight = 0,
		globalDynamic = {},
		isAccountOpen = false,
		isMenuOpen = false,
		isSearchOpen = false,
		isScrollingUp = false,
		scrollY = 0,
	} = {}) {
		makeObservable(this, {
			isAccountOpen: observable,
			isMenuOpen: observable,
			isSearchOpen: observable,
			isScrollingUp: observable,
			scrollY: observable,
			isLoggedIn: computed,
			isScrolledToTop: computed,
			showAccountButton: computed,
			showHeaderOverlay: computed,
			showHeaderShadow: computed,
			showSearchShadow: computed,
		});

		this.globalDynamic = globalDynamic;
		this.headerHeight = headerHeight;
		this.isAccountOpen = isAccountOpen;
		this.isMenuOpen = isMenuOpen;
		this.isScrollingUp = isScrollingUp;
		this.isSearchOpen = isSearchOpen;
		this.scrollY = scrollY;
	}

	get isLoggedIn() {
		return this.globalDynamic.isLoggedIn;
	}

	get isScrolledToTop() {
		return this.scrollY <= 5;
	}

	get showAccountButton() {
		const { logoutLink = '' } = this.globalDynamic;

		return !this.isAccountOpen && logoutLink.length > 0;
	}

	get showHeaderOverlay() {
		const { logoutLink } = this.globalDynamic;

		return this.isAccountOpen && logoutLink.length > 0;
	}

	get showHeaderShadow() {
		return !this.isSearchOpen && (this.isAccountOpen || this.isMenuOpen || !this.isScrolledToTop);
	}

	get showSearchShadow() {
		return this.isSearchOpen && !this.isScrolledToTop;
	}
}

export { HeaderMobile };
