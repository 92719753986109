'use client';

import cn from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { noop } from '~/util/noop';
import { TextLink } from '~/components/Buttons/Components/TextLink';
import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { ViewState } from '~/util/viewState/Components/ViewState';

import styles from '#/layout/email-signup.module.scss';

export const EmailSignUpFormViewState = observer(({ emailSignUpFormStore: { viewState } }) => <ViewState viewState={viewState} />);

export const EmailSignUpForm = observer(({ emailSignUpFormStore = {}, clickHandler = noop }) => {
	const {
		form: {
			fields: {
				email,
			},
			reactProps,
		},
		hideLegend,
		locationId,
		showSubscriptionFailureMessage = false,
		showEAndMWebModalSubmitBtn = false,
		useInlineSubmitButton,
		isEAndMWebModal = false,
	} = emailSignUpFormStore;

	const {
		key,
		...formReactProps
	} = reactProps;

	const inlineSubmitButton = (
		(useInlineSubmitButton || showEAndMWebModalSubmitBtn) &&
		<button aria-live={showEAndMWebModalSubmitBtn ? 'polite' : undefined}
			className={cn('ButtonSupport', {
				'tw-ml-1 tw--mt-[2px]': isEAndMWebModal,
			})} data-qa="email-signup-submit">
				Sign Up
		</button>
	);
	const bottomSubmitButton = (
		(!useInlineSubmitButton && !isEAndMWebModal) && <button className={`Button ${styles['email-signup-form-button']}`} data-qa="email-signup-submit" onClick={clickHandler}>Sign Up</button>
	);

	const subscriptionFailureMessage = (
		showSubscriptionFailureMessage && <div className="validation tw-text-red" aria-live="polite" data-qa="subscription-failure-message">Please enter a valid email address.</div>
	);

	return (
		<div className={`${styles['email-signup-form-container']} email-signup-form-container-${locationId} formz`}>
			<form key={key} {...formReactProps} data-allow-tr-link-event>
				<div className={styles['email-signup-form-container']}>
					<legend className={cn('Heading Heading--5 tw-text-gray tw-font-light', styles['email-signup-form-legend'], { 'tw-sr-only': hideLegend })}><h2>Sign Up For Emails</h2></legend>

					<EmailField afterControl={inlineSubmitButton} afterValidation={subscriptionFailureMessage} field={email} />

					<p className={styles['email-signup-form-disclaimer']}>
						We will not share, sell or trade your email. <TextLink data-qa="email-sign-up-privacy-policy" href="/privacy/" target={isEAndMWebModal ? '_blank' : undefined}><span className={'tw-text-[13px]'}>Privacy Policy</span></TextLink>
					</p>
					{bottomSubmitButton}
				</div>
			</form>
		</div>
	);
});
