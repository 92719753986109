import {
	action, autorun, observable, makeObservable,
} from 'mobx';
import { fromPromise } from 'mobx-utils';
import axios from 'axios';

import {
	defaultEmailSignUpKey,
	emailSignUpEndpointUrl,
	emailSignUpSources,
} from '~/email-signup/emailSignUp.constants';
import {
	formModel,
	formSettings,
} from '~/email-signup/email-signup-settings';
import { MagicSpinner } from '~/components/MagicSpinner';
import { EmailSignUpForm } from '~/email-signup/Components/EmailSignUpForm';
import { EmailSignUpSuccess } from '~/email-signup/Components/EmailSignUpSuccess';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { redirect } from '~/util/redirect';
import { addToUrl } from '~/util/addToUrl';
import { isOnServer } from '~/global/global.constants';
import { FormSubmitEventTrackingStore } from '~/tracking/form-submit-event/Stores/FormSubmitEvent.tracking.store';
import { EMAIL_SIGN_UP_FORM_VARIANT } from '~/email-signup/emailSignUp.types';
import { noop } from '~/util/noop';

export class EmailSignUpFormStore {
	emailSignUpResults = null;

	showLoadingSpinner = false;

	emailSignUpFormSuccessCallback;

	form = null;

	hideLabel = false;

	hideLegend = false;

	isSubmitted = false;

	showSubscriptionFailureMessage = false;

	locationId = '';

	magicSpinnerProps = null;

	redirectOnSuccess = false;

	source = '';

	useInlineSubmitButton = false;

	variant = undefined;

	viewState = null;

	viewStateDisposer = null;

	constructor() {
		makeObservable(this, {
			emailSignUpResults: observable,
			isSubmitted: observable,
			showLoadingSpinner: observable,
			showSubscriptionFailureMessage: observable,
			submitHandler: action.bound,
			processForm: action.bound,
		});
	}

	async submitHandler() {
		const {
			model,
			plugins: {
				formValidator,
				formValidator: { hasErrors },
			},
		} = this.form;

		formValidator.validateForm();

		if (!hasErrors) {
			try {
				const emailSignUpPromise = axios.post(emailSignUpEndpointUrl, model);
				this.emailSignUpResults = fromPromise(emailSignUpPromise);

				const response = await emailSignUpPromise;
				return response;
			} catch (error) {
				return error;
			}
		}
		return false;
	}

	async processForm(formPromise) {
		const formResponse = await formPromise;

		const {
			response: {
				status = null,
				data: {
					errors = [],
				} = {},
			} = {},
		} = formResponse;

		if (status === 400 && errors[0].errorKey === 'subscriptionFailure') {
			this.showSubscriptionFailureMessage = true;
		}
		return formResponse;
	}

	trackFormSubmission() {
		const formSubmitTracking = new FormSubmitEventTrackingStore();
		formSubmitTracking.trackFormSubmit(this.form.id.value_);
	}

	get isEAndMWebModal() {
		return this.variant === EMAIL_SIGN_UP_FORM_VARIANT.EANDM_WEB;
	}

	get showEAndMWebModalSubmitBtn() {
		return Boolean(this.isEAndMWebModal && this.form.model.email.length);
	}
}

export const EmailSignUpFormStoreFactory = {
	create: ({
		emailSignUpFormSuccessCallback = noop,
		hideLabel = false,
		hideLegend,
		locationId = defaultEmailSignUpKey,
		magicSpinnerProps = {
			isLoading: true,
		},
		redirectOnSuccess = false,
		source = emailSignUpSources.DEFAULT,
		showSubscriptionFailureMessage = false,
		useInlineSubmitButton = true,
		variant,
	} = {}) => {
		const emailSignUpFormStore = new EmailSignUpFormStore();
		const viewStateOptions = {
			scrollToTopOfModal: false,
		};
		const viewState = new ViewStateStore(emailSignUpFormStore, {
			loading: {
				component: MagicSpinner,
				onEnter: () => {
					return emailSignUpFormStore.magicSpinnerProps;
				},
			},
			form: {
				component: EmailSignUpForm,
				onEnter: () => {
					return { emailSignUpFormStore };
				},
			},
			success: {
				component: EmailSignUpSuccess,
				onEnter: () => {
					return { emailSignUpFormStore };
				},
			},
		}, viewStateOptions);

		const form = new FormBuilder(
			formModel({ source }),
			formSettings(emailSignUpFormStore.submitHandler, emailSignUpFormStore.processForm, locationId, hideLabel),
		);

		let viewStateDisposer = null;
		if (!isOnServer) {
			viewStateDisposer = autorun(() => {
				if (emailSignUpFormStore.emailSignUpResults !== null) {
					emailSignUpFormStore.emailSignUpResults.case({
						rejected: () => {
							emailSignUpFormStore.viewState.goTo('form');
						},
						pending: () => {
							emailSignUpFormStore.viewState.goTo('loading');
						},
						fulfilled: (resp) => {
							emailSignUpFormStore.trackFormSubmission();

							const { data: { email = '' } } = resp;

							emailSignUpFormStore.emailSignUpFormSuccessCallback?.();

							emailSignUpFormStore.viewState.goTo('success', { email });

							emailSignUpFormStore.isSubmitted = true;

							if (emailSignUpFormStore.redirectOnSuccess) {
								redirect(addToUrl('/opt-in', `usr=${btoa(email)}&isNewSubscription=true`));
							}
						},
					});
				}
			});
		}

		Object.assign(emailSignUpFormStore, {
			emailSignUpFormSuccessCallback,
			form,
			hideLabel,
			hideLegend,
			locationId,
			magicSpinnerProps,
			redirectOnSuccess,
			showSubscriptionFailureMessage,
			source,
			useInlineSubmitButton,
			variant,
			viewState,
			viewStateDisposer,
		});

		emailSignUpFormStore.viewState.goTo('form');

		return emailSignUpFormStore;
	},
};
