import { getCookie } from 'cookies-next';
import { action, makeObservable } from 'mobx';

import { isOnServer } from '~/global/global.constants';

class FeatureTogglesModel {
	globalDynamicModel;

	toggleCookie;

	constructor(globalDynamicModel, toggleCookie) {
		makeObservable(this, {
			getToggles: action.bound,
			getToggleByName: action.bound,
			isOn: action.bound,
			getToggleClassNames: action.bound,
		});

		this.toggleCookie = isOnServer ? toggleCookie : getCookie('featureToggles');
		this.globalDynamicModel = globalDynamicModel;
	}

	getToggles() {
		return this.globalDynamicModel?.activeFeatureToggles || [];
	}

	getToggleByName(name) {
		return this.getToggles().find(toggle => toggle === name) || {};
	}

	isOn(featureName) {
		// if (this.toggleCookie) {
		// 	return this.toggleCookie.indexOf(featureName) > -1;
		// }

		// fallback to global dynamic for now
		const toggles = this.getToggles();
		if (!Array.isArray(toggles)) {
			return false;
		}
		return toggles.indexOf(featureName) > -1;
	}

	// @deprecated
	getToggleClassNames() {
		return '';
	}
}

export { FeatureTogglesModel };
