'use client';

import React, {
	createContext, useContext, ReactNode, useRef,
} from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import type { MenuRegistrationRecord } from '~/components/flyout-menu/useForbidMultipleOpenMenus';
import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { GlobalModelFactory } from '~/global/Models/Global.model';
import { ContactUsFormStoreFactory } from '~/contact-us/Stores/contactUsForm.store';

export type GlobalContextProps = {
	children: ReactNode
	mockContext?: any,
	pageProps: AppConfigPageProps,
};

const globalContext = createContext<any>({});

export const GlobalContextProvider = ({ children, pageProps }: GlobalContextProps) => {
	const { personalizationScope } = pageProps;
	const menus = useRef<MenuRegistrationRecord[]>([]);
	const pathname = usePathname();
	const router = useRouter();
	const searchParams = useSearchParams();

	const globalModel = GlobalModelFactory.create({
		// @ts-ignore
		pageProps,
		// @ts-ignore
		pathname,
		router,
		searchParams,
	});
	const contactUsFormStore = ContactUsFormStoreFactory.create();
	const value = Object.assign(globalModel, {
		menus,
		contactUsFormStore,
		personalizationScope,
	});

	return (
		<globalContext.Provider value={value}>
			{children}
		</globalContext.Provider>
	);
};

export const useGlobalContext = () => useContext(globalContext);
