import { apiUrl } from '~/global/global.constants';

const defaultEmailSignUpKey = 'DEFAULT';

const emailSignUpEndpointUrl = `${apiUrl}/api/email-preferences/create-subscription`;

const emailSignUpSources = {
	DEFAULT: 'FOOTER',
	FOOTER: 'FOOTER',
	MOBILE: 'MOBILE',
};

export {
	defaultEmailSignUpKey,
	emailSignUpEndpointUrl,
	emailSignUpSources,
};
