import {
	_async, model, Model, idProp, prop, modelFlow,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { PersonalizationDecisionsModel } from '~/personalization/Models/PersonalizationDecisions.model';
import { PERSONALIZATION_SCOPE, type IPropositions } from '~/personalization/Personalization.types';

@model(`${modelNamespace.PERSONALIZATION}/Decision`)
export class PersonalizationStore extends Model({
	id: idProp,
	decisionsModel: prop<PersonalizationDecisionsModel | undefined>().withSetter(),
}) {
	@modelFlow
	sendDisplayEvent = _async(function* (this: PersonalizationStore, decision: PersonalizationDecisionModel) {
		try {
			if (typeof window !== 'undefined') {
				const tsWindow: any = window;
				const {
					alloy,
				} = tsWindow;
				yield alloy('sendEvent', {
					xdm: {
						eventType: 'decisioning.propositionDisplay',
						_experience: {
							decisioning: {
								propositions: [{
									id: decision.decisionId,
									scope: decision.scope,
									scopeDetails: decision.scopeDetails,
								}],
								propositionEventType: {
									display: 1,
								},
							},
						},
					},
					data: {
						__adobe: {
							target: {
								correlationId: decision.correlationId,
								targetEventType: 'impression',
							},
						},
					},
				});
			}
		} catch (error: any) {
			console.log(error);
			// throw new Error(error);
		}
	});

	@modelFlow
	sendInteractEvent = _async(function* (this: PersonalizationStore, decision: PersonalizationDecisionModel) {
		try {
			if (typeof window !== 'undefined') {
				const tsWindow: any = window;
				const {
					alloy,
				} = tsWindow;
				yield alloy('sendEvent', {
					xdm: {
						eventType: 'decisioning.propositionInteract',
						_experience: {
							decisioning: {
								propositions: [{
									id: decision.decisionId,
									scope: decision.scope,
									scopeDetails: decision.scopeDetails,
								}],
								propositionEventType: {
									interact: 1,
								},
							},
						},
					},
					data: {
						__adobe: {
							target: {
								correlationId: decision.correlationId,
								targetEventType: 'interact',
							},
						},
					},
				});
			}
		} catch (error: any) {
			console.log(error);
			// throw new Error(error);
		}
	});

	getPersonalizationDecision(scope: PERSONALIZATION_SCOPE): IPropositions | undefined {
		if (!this.decisionsModel || !this.decisionsModel?.decisions?.propositions?.length) {
			return undefined;
		}

		const [decision] = this.decisionsModel.groupDecisionsByScope[scope] ?? [];

		return decision;
	}
}
