import React from 'react';
import { observer } from 'mobx-react';

const MaxLengthRemainingCountdown = observer(class MaxLengthRemainingCountdown extends React.Component {
	render() {
		const lengthRemainingPlugin = this?.props?.field?.plugins?.maxlengthRemainingCountdown;
		const { ariaDescribedById } = this.props;

		if (!lengthRemainingPlugin) {
			return null;
		}
		const {
			remainingCharacterCount,
			classNames,
		} = lengthRemainingPlugin;
		const oneCharRemaining = remainingCharacterCount === 1;

		return (
			<div data-qa={this.props['data-qa']} aria-atomic aria-live="polite" className={classNames} id={ariaDescribedById}>
				{
					this.props.customRemainingCharCount
						?
						this.props.customRemainingCharCount(remainingCharacterCount)
						:
						`(${remainingCharacterCount} character${oneCharRemaining ? '' : 's'} remain${oneCharRemaining ? 's' : ''})`
				}
			</div>
		);
	}
});

export { MaxLengthRemainingCountdown };
