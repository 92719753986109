import React, { ComponentProps } from 'react';
import classNames from 'classnames';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

import { LinkBase } from './LinkBase';

export interface IProps extends Omit<ComponentProps<typeof LinkBase>, 'color'> {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
}

export const TextLink = (props: IProps) => {
	const {
		color,
		hasNoUnderline = false,
		className = '',
		...anchorProps
	} = props;
	const textLinkClassName = buildTextLinkClassNames(color, hasNoUnderline);

	return (
		<LinkBase
			{...anchorProps}
			className={classNames(textLinkClassName, {
				[className]: className,
			})}
		>
			{props.children}
		</LinkBase>
	);
};
