import {
	observable, action, computed, makeObservable,
} from 'mobx';

import { isEngage, isOnServer, liveChatEnabled } from '~/global/global.constants';

const AGENT_STATES = {
	OFFLINE: 'offline',
	ONLINE: 'online',
};

class LiveChatThirdParty {
	chatAgentOnline;

	chatStarted;

	chatQueued;

	featureTogglesModel;

	onCloseFocusElement;

	constructor() {
		makeObservable(this, {
			chatAgentOnline: observable,
			chatStarted: observable,
			chatQueued: observable,
			showChatIcon: computed,
			onAvailabilityChanged: action.bound,
			onCustomerStatusChanged: action.bound,
			onVisibilityChanged: action.bound,
			openChat: action.bound,
			setChatAgentOnline: action.bound,
		});
	}

	get showChatIcon() {
		return this.chatAgentOnline || this.chatStarted || this.chatQueued;
	}

	setChatAgentOnline(chatAgentOnline) {
		this.chatAgentOnline = chatAgentOnline;
	}

	onAvailabilityChanged(data) {
		if (data.availability === 'online') {
			this.setChatAgentOnline(true);
			window.LiveChatWidget?.call('minimize');
		} else {
			this.setChatAgentOnline(false);
			if (!this.showChatIcon) {
				window.LiveChatWidget?.call('hide');
			}
		}
	}

	onCustomerStatusChanged(data) {
		switch (data.status) {
			case 'queued':
				// customer is in queue
				this.chatQueued = true;
				break;
			case 'chatting':
				// customer is currently chatting
				this.chatStarted = true;
				this.chatQueued = false;
				break;
			case 'invited':
				// customer received an invitation but didn't start the chat
				break;
			case 'browsing':
				// customer is in idle state, not queued, not chatting, and didn't receive an invitation
				this.chatStarted = false;
				this.chatQueued = false;
				break;
			default:
				break;
		}
	}

	onVisibilityChanged(data) {
		switch (data.visibility) {
			case 'minimized':
			case 'hidden':
				// set focus to original trigger and clean it up, if applicable
				this.onCloseFocusElement?.focus?.();
				this.setOnCloseFocusElement(null);
				break;
			default:
				break;
		}
	}

	openChat() {
		window.LiveChatWidget?.call('maximize');
	}

	setOnCloseFocusElement(onCloseFocusElement = null) {
		this.onCloseFocusElement = onCloseFocusElement;
	}

	init({ hideChatBubbleDesktop = false }) {
		// show the minimized chat bubble widget, but allow a previously expanded chat from another tab to remain expanded
		window.LiveChatWidget?.on?.('ready', (data) => {
			const {
				customerData,
				state,
			} = data;

			// we hide the mimimized chat widget by default with css, but allow it to be visible if the following toggled are turned off
			const chatWidgetMinimizedEl = document.querySelector('#chat-widget-minimized');

			if (!this.featureTogglesModel.isOn('FOOTER_CHAT_BUTTON_MOBILE')) {
				chatWidgetMinimizedEl?.classList?.add('sm-not-hidden');
			}

			if (!hideChatBubbleDesktop && this.featureTogglesModel.isOn('FOOTER_CHAT_BUBBLE_DESKTOP')) {
				chatWidgetMinimizedEl?.classList?.add('md-not-hidden');
			} else {
				chatWidgetMinimizedEl?.classList?.remove('md-not-hidden');
			}

			if (state.availability === 'online' && state.visibility === 'maximized') {
				window.LiveChatWidget?.call('minimize');
			}

			if (state.availability === 'online') {
				this.setChatAgentOnline(true);
			}

			if (customerData.status === 'queued') {
				this.chatQueued = true;
			} else if (customerData.status === 'chatting') {
				this.chatStarted = true;
			}

			if (state.availability !== 'online' && !this.showChatIcon) {
				window.LiveChatWidget?.call('hide');
			}

			window.LiveChatWidget?.on('customer_status_changed', this.onCustomerStatusChanged);
			window.LiveChatWidget?.on('availability_changed', this.onAvailabilityChanged);
			window.LiveChatWidget?.on('visibility_changed', this.onVisibilityChanged);
		});
	}
}

const LiveChatThirdPartyFactory = {
	create: ({ featureTogglesModel = {} }) => {
		if (isOnServer || !liveChatEnabled || isEngage) {
			return undefined;
		}

		const instance = new LiveChatThirdParty();

		instance.setChatAgentOnline(false);
		instance.chatAgentState = AGENT_STATES.OFFLINE;
		instance.chatStarted = false;
		instance.chatQueued = false;
		instance.featureTogglesModel = featureTogglesModel;
		instance.onCloseFocusElement = null;

		return instance;
	},
};

export {
	LiveChatThirdPartyFactory,
	AGENT_STATES,
};
